@font-face {
    font-family: 'selimaregular';
    src: url('selima_-webfont.eot');
    src: url('selima_-webfont.eot?#iefix') format('embedded-opentype'),
         url('selima_-webfont.woff') format('woff'),
         url('selima_-webfont.ttf') format('truetype'),
         url('selima_-webfont.svg#selimaregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

